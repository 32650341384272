import { post } from '../../utils/request';

export function getQuestion(data) {
    console.log(data)
    return post('api/wenjuan/api/v1/answer/showDetail', data)
}

export function getQuestionSubmit(data) {
    return post('api/wenjuan/api/v1/answer/submitQuestion', data);
}
/*  */