<template>
  <div class="question">
    <!-- 封面 标题 导语部分 -->
    <!-- <div>您需要在{{countDown}}之前完成答卷哦</div> -->
    <div
      class="questionHeader"
      v-if="showImg"
    >
      <!-- 有封面 -->
      <div class="headerImg">
        <img
          :src="$$setImgSrc('MQN_coverIcon.png')"
          alt=""
        > 
      </div>
      <div class="imgCountDown">
         <svg width="20" height="17" viewBox="0 0 39 35" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="19.7632" cy="18.5302" r="14.4091" stroke="#333333" stroke-width="3"/>
          <path d="M7.56621 1.56055L2.26318 7.92418" stroke="#333333" stroke-width="3" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M31.9602 1.56055L37.2632 7.92418" stroke="#333333" stroke-width="3" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M18.6086 13.4343V19.6968L22.6086 24.4343" stroke="#FF694A" stroke-width="3" stroke-linecap="round"/>
          </svg>
        <div style="paddingLeft:5px;">{{secondToTime(questionData.countDown)}}</div>
      </div>

      <div class="questionTitle">
        {{ questionData.questionTitle }}
      </div>
      <div class="introduction">
        {{ questionData.introduction }}
      </div>
    </div>
    <div
      class="questionHeader"
      v-else
    >
      <!-- 无封面 -->
      <div class="questionTitle">
        <div class="titleContent">{{ questionData.questionTitle }}  </div>
        <div class="clock">
          <div
          :class="[showCount ? 'countDown' : 'countDownHide']"
          id="count"
          @click="showCountClick"
          > 
          <svg width="20" height="17" viewBox="0 0 39 35" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="19.7632" cy="18.5302" r="14.4091" stroke="#333333" stroke-width="3"/>
          <path d="M7.56621 1.56055L2.26318 7.92418" stroke="#333333" stroke-width="3" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M31.9602 1.56055L37.2632 7.92418" stroke="#333333" stroke-width="3" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M18.6086 13.4343V19.6968L22.6086 24.4343" stroke="#FF694A" stroke-width="3" stroke-linecap="round"/>
          </svg>
          <div style="paddingLeft:5px;">{{secondToTime(questionData.countDown)}}</div>
        </div>
      </div>
      </div>
      <div class="introduction">
        {{ questionData.introduction }}
      </div>
    </div>
    <!-- 答题部分 -->
    <div class="questionContent">
      <!-- 填空 单选 多选 评价 简答 -->
      <!-- 题目有图片: 单选 多选 简答 -->

      <template v-for="(item, index) in questionData.questionList">
        <!-- 单选题 -->
        <div
          class="questionBody"
          :key="'question' + index"
          v-if="item.questionType === 0"
        >
          <div class="questionTitle">
            <div>

              <span>{{ returnQuestionIndex(index) }}.{{ item.questionTitleList && item.questionTitleList.title }}</span>
              <span
                v-if="item.isMust === 1"
                style="color: #fc3f33"
              >*</span>
              <span
                v-if="emptyList.includes(index)"
                style="color: #fc3f33;fontSize:12px"
              >
                本题必答
              </span>
            </div>
            <div
              class="questionTitleImg"
              v-if="item.questionTitleList && item.questionTitleList.imgList.length !== 0"
            >
              <div
                style="margin:1%;position:relative"
                v-for="(imgItem, imgIndex) in item.questionTitleList.imgList"
                :key="'img' + imgIndex"
              >
                <img
                  style=""
                  :src="$$img(imgItem.imgUrl)+'_m.'+(imgItem.mediaType||'jpg')"
                  alt=""
                  :preview="'title'+index"
                >
                <!-- <div class="imgIcon">
                  <i class="el-icon-search"></i>
                </div> -->
              </div>
            </div>
          </div>
          <div>
            <el-radio-group
              v-model="formValue[item.questionId]"
              @change="changeRadio"
            >
              <template v-for="(radioItem,radioIndex) in item.optionList">
                <!-- 选项没图片 -->
                <el-radio
                  v-if="radioItem.imgList && radioItem.imgList.length === 0"
                  :key="'radio'+radioIndex"
                  :label="radioItem.optionId"
                  border
                  size="medium"
                >{{optionTitle(radioItem,radioIndex)}}</el-radio>
                <!-- 选项有图片 -->
                <el-radio
                  v-else
                  :key="'radio'+radioIndex"
                  :label="radioItem.optionId"
                  size="medium"
                  style="display:flex;border:1px solid #DCDFE6;padding: 3% 28% 3% 2%;marginBottom:3%;borderRadius:4px"
                >
                  <div style="position:relative;top:-1%">
                    <div class="optionText">
                      {{optionTitle(radioItem,radioIndex)}}
                    </div>
                    <div style="position:relative;margin:10% 0">
                      <img
                        style=""
                        :src="$$img(radioItem.imgList !== 0 && radioItem.imgList[0].imgUrl)+'_m.'+(radioItem.mediaType||'jpg')"
                        alt=""
                        :preview="'option'+index"
                        :preview-text="optionTitle(radioItem,radioIndex)"
                      >
                      <!-- <div class="imgIcon">
                        <i class="el-icon-search"></i>
                      </div> -->
                    </div>
                  </div>
                </el-radio>
              </template>
            </el-radio-group>
          </div>
        </div>
        <!-- 多选题 -->
        <div
          class="questionBody"
          :key="'question' + index"
          v-if="item.questionType === 1"
        >
          <div class="questionTitle">
            <div>
              <span>{{ returnQuestionIndex(index) }}.{{ item.questionTitleList && item.questionTitleList.title }}</span>
              <span
                v-if="item.isMust === 1"
                style="color: #fc3f33"
              >*</span>
              <span
                v-if="emptyList.includes(index)"
                style="color: #fc3f33;fontSize:12px"
              >
                本题必答
              </span>
              <br />
              <span
                v-if="isOptionNumList(index)"
                style="color: #fc3f33;fontSize:12px"
              >
                本题只可选择{{item.optionMin}}-{{item.optionMax}}项
              </span>
            </div>
            <div
              class="questionTitleImg"
              v-if="item.questionTitleList && item.questionTitleList.imgList.length !== 0"
            >
              <div
                style="margin:1%;position:relative"
                v-for="(imgItem, imgIndex) in item.questionTitleList.imgList"
                :key="'img' + imgIndex"
              >
                <img
                  style=""
                  :src="$$img(imgItem.imgUrl)+'_m.'+(imgItem.mediaType||'jpg')"
                  alt=""
                  :preview="'title'+index"
                >
                <!-- <div class="imgIcon">
                  <i class="el-icon-search"></i>
                </div> -->
              </div>
            </div>

          </div>

          <div>
            <el-checkbox-group
              v-model="formValue[item.questionId]"
              @change="changeCheckbox"
            >
              <template v-for="(boxItem,boxIndex) in item.optionList">
                <!-- 选项没图片 -->
                <el-checkbox
                  v-if="boxItem.imgList.length === 0"
                  :key="'checkbox'+boxIndex"
                  :label="boxItem.optionId"
                  border
                  size="medium"
                >{{optionTitle(boxItem,boxIndex)}}</el-checkbox>
                <!-- 选项有图片 -->
                <el-checkbox
                  v-else
                  :key="'checkbox'+boxIndex"
                  :label="boxItem.optionId"
                  size="medium"
                  style="display:flex;border:1px solid #DCDFE6;padding: 3% 25% 3% 2%;marginBottom:3%;borderRadius:4px"
                >
                  <div style="position:relative;top:-1%">
                    <div class="optionText">
                      {{optionTitle(boxItem,boxIndex)}}
                    </div>
                    <div style="position:relative;margin:10% 0">
                      <img
                        style=""
                        :src="$$img(boxItem.imgList !== 0 && boxItem.imgList[0].imgUrl)+'_m.'+(boxItem.mediaType||'jpg')"
                        alt=""
                        :preview="'option'+index"
                        :preview-text="optionTitle(boxItem,boxIndex)"
                      >
                      <!-- <div class="imgIcon">
                        <i class="el-icon-search"></i>
                      </div> -->
                    </div>
                  </div>
                </el-checkbox>
              </template>

            </el-checkbox-group>
          </div>
        </div>
        <!-- 简答题 -->
        <div
          class="questionBody"
          :key="'question' + index"
          v-if="item.questionType === 2"
        >
          <div class="questionTitle">
            <div>
              <span>{{ returnQuestionIndex(index) }}.{{ item.questionTitleList && item.questionTitleList.title }}</span>
              <span
                v-if="item.isMust === 1"
                style="color: #fc3f33"
              >*</span>
              <span
                v-if="emptyList.includes(index)"
                style="color: #fc3f33;fontSize:12px"
              >
                本题必答
              </span>
            </div>

            <div
              class="questionTitleImg"
              v-if="item.questionTitleList && item.questionTitleList.imgList.length !== 0"
            >
              <div
                style="margin:1%;position:relative"
                v-for="(imgItem, imgIndex) in item.questionTitleList.imgList"
                :key="'img' + imgIndex"
              >
                <img
                  style=""
                  :src="$$img(imgItem.imgUrl)+'_m.'+(imgItem.mediaType||'jpg')"  
                  alt=""
                  :preview="'title'+index"
                >
                <!-- <div class="imgIcon">
                  <i class="el-icon-search"></i>
                </div> -->
              </div>
            </div>
          </div>

          <div>
            <el-input
              type="textarea"
              v-model="formValue[item.questionId]"
              maxlength="100"
              show-word-limit
              :autosize="{ minRows: 5, maxRows: 6}"
            >
            </el-input>
          </div>
        </div>
        <!-- 评价题 -->
        <div
          class="questionBody"
          :key="'question' + index"
          v-if="item.questionType === 3"
        >
          <div class="questionTitle">
            <span>{{ returnQuestionIndex(index) }}.{{ item.questionTitleList && item.questionTitleList.title }}</span>
            <span
              v-if="item.isMust === 1"
              style="color: #fc3f33"
            >*</span>
            <span
              v-if="emptyList.includes(index)"
              style="color: #fc3f33;fontSize:12px"
            >
              本题必答
            </span>
          </div>

          <div>
            <el-rate
              v-model="formValue[item.questionId]"
              show-text
              :texts="rateText(item.optionList)"
              text-color="#FD463B"
            >
            </el-rate>
          </div>
        </div>
        <!-- 填空题 -->
        <div
          class="questionBody"
          :key="'question' + index"
          v-if="item.questionType === 4"
        >
          <div class="questionTitle">
            <span>{{ returnQuestionIndex(index) }}.{{ item.questionTitleList && item.questionTitleList.title }}</span>
            <span
              v-if="item.isMust === 1"
              style="color: #fc3f33"
            >*</span>
            <span
              v-if="emptyList.includes(index)"
              style="color: #fc3f33;fontSize:12px"
            >
              本题必答
            </span>
          </div>
          <div>
            <el-input
              v-model="formValue[item.questionId]"
              maxlength="20"
            ></el-input>
          </div>

        </div>
        <!-- 段落说明 -->
        <div
          class="questionBody"
          :key="'question' + index"
          v-if="item.questionType === 5"
        >
          <div class="questionParagraph">
            <span v-html="item.questionTitleList && item.questionTitleList.title"></span>
          </div>
        </div>
      </template>
    </div>
    <!-- 提交按钮 -->
    <div
      class="questionSubmit"
      @click="clickSubmit"
      v-if="questionData.questionList && questionData.questionList.length !== 0"
    >
      提交问卷
    </div>

    <el-dialog
      title=""
      :visible.sync="finishDialogVisible"
      width="85%"
      center
    >
      <span>该问卷倒计时结束，请刷新页面后重新回答</span>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="cancelFinish">取 消</el-button>
        <el-button
          type="primary"
          @click="okFinish"
        >确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title=""
      :visible.sync="submitDialogVisible"
      width="85%"
      center
    >
      <span>该问卷倒计时结束，系统已为您自动提交</span>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="okSubmit">取 消</el-button>
        <el-button
          type="primary"
          @click="okSubmit"
        >确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { getQuestion, getQuestionSubmit } from "../../api/question/question";
// import ImageSSS from '../../components/image.vue'
export default {
  name: "question",
  // components:{
  //   ImageSSS
  // },
  data() {
    return {
      libId: "",
      countDown: 0, // 倒计时
      finishDialogVisible: false,
      submitDialogVisible: false,
      questionData: {}, // 题目数据
      formValue: {}, // 表单填写数据
      rules: {}, // 表单校验
      beginTime: "", // 开始作答时间
      endTime: "", // 结束作答时间
      showCount: false, // 是否展示倒计时时间
      emptyList: [], // 未填的必答题index
      optionNumList: [], // 多选题已选数目不对的index
      answerData: [], // 提交表单数据list
      graphIndexList: [], // 段落说明的indexlist
      countFlag: true, // 是否是正计时
      clickFlag: false // 是否手动点击提交问卷
    };
  },
  created() {
    this.initData();

    if (!localStorage.getItem("__________cccccckkkkkkkkkkk_______")) {
      localStorage.setItem(
        "__________cccccckkkkkkkkkkk_______",
        this._id_create()
      );
    }
  },
  mounted() {
    this.initBeginTime();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  computed: {
    showImg() {
      return this.questionData.img !== undefined;
    }
  },

  methods: {
    initData() {
      this.libId = this.getQuery("libid");
      getQuestion({ libId: this.libId ? this.libId : "2111000036" }).then(
        res => {
          console.log(res, 1111);
          if (res && res.message === "success") {
            this.questionData = { ...res.result };
            this.questionData.questionList.length !== 0 &&
              this.questionData.questionList.forEach(item => {
                if (item.questionType === 3) {
                  item.optionList.reverse();
                }
              });
            this.countFlag =
              res.result.countDown === null || res.result.countDown === 0;
            this.showCount = res.result.img !== undefined;
            if (res.result.questionList.length) {
              res.result.questionList.forEach((item, index) => {
                // formValue存放问卷填写数据，formValue的key value分别对应题目id、题目填写信息
                if (item.questionType === 1) {
                  // 多选题是array
                  this.$set(this.formValue, item.questionId, []);
                } else if (item.questionType === 3) {
                  // 评价题是number
                  this.$set(this.formValue, item.questionId, 0);
                } else if (item.questionType === 5) {
                  // 题目序号是使用的index，段落说明也占一个index但没有序号，所以需要标记，防止影响段落说明后题目序号展示
                  this.graphIndexList.push(index);
                } else {
                  this.$set(this.formValue, item.questionId, "");
                }
              });
            }
            console.log(this.questionData, "questionData");
            console.log(this.formValue, "formvalue");
          }
        }
      );
    },

    _id_create() {
      return "xxxxxx-xxx-4xxx-yxx-xxxxxx".replace(/[xy]/g, function(c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
    getQuery(name) {
      const search = window.location.href.split("?")[1];

      if (!search) return null;
      const item = search.split("&");

      if (item) {
        for (var i = 0; i < item.length; i++) {
          var pair = item[i].split("=");
          if (pair[0] == name) {
            return pair[1];
          }
        }
      } else {
        console.log(`${name} is not in search`);
        return null;
      }
    },
    // 页面初始时保存开始作答时间
    initBeginTime() {
      let self = this;
      this.beginTime = this.formatDate("yyyy-MM-dd hh:mm:ss");
      this.timer = setInterval(() => {
        if (self.countFlag) {
          self.questionData.countDown += 1000;
        } else {
          self.questionData.countDown -= 1000;
          if (self.questionData.countDown === 0) {
            clearInterval(this.timer);
            this.finishTime();
          }
        }
      }, 1000);
    },
    // 获取当前时间
    formatDate(fmt) {
      let d = new Date();
      let o = {
        "M+": d.getMonth() + 1, //月份
        "d+": d.getDate(), //日
        "h+": d.getHours(), //小时
        "m+": d.getMinutes(), //分
        "s+": d.getSeconds(), //秒
        "q+": Math.floor((d.getMonth() + 3) / 3), //季度
        S: d.getMilliseconds() //毫秒
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (d.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
        }
      }
      return fmt;
    },
    // 处理选项title
    optionTitle(item, index) {
      return String.fromCharCode(65 + index) + "." + item.optionTitle;
    },
    isOptionNumList(i) {
      let flag = false;
      for (let index = 0; index < this.optionNumList.length; index++) {
        const element = this.optionNumList[index];
        if (element.index === i) {
          flag = true;
          break;
        }
      }
      return flag;
    },
    // 处理题目序号
    returnQuestionIndex(index) {
      let graphCount = 0;
      let returnIndex = 0;
      // 如果题目序号index在所有段落说明index之前则直接返回题目序号
      if (index < Math.min.apply(Math, this.graphIndexList)) {
        returnIndex = index + 1;
      } else {
        // 如果在第一个段落说明之后则需要判断当前题目之前有几个段落说明，然后减去对应的段落说明index
        if (index > Math.max.apply(Math, this.graphIndexList)) {
          // 题目在所有段落说明之后的时候，直接减去段落说明的数量即可
          returnIndex = index - this.graphIndexList.length + 1;
        } else {
          // 题目在多个段落说明中间的时候需要判断题目之前有几个段落说明
          for (let i = 0; i < this.graphIndexList.length; i++) {
            if (index < this.graphIndexList[i]) {
              graphCount = i;
              break;
            }
          }
          returnIndex = index - graphCount + 1;
        }
      }

      return returnIndex;
    },
    // 切换radio
    changeRadio(value) {
      console.log(value);
    },
    // 切换checkbox
    changeCheckbox(value) {
      console.log(value);
    },
    // 处理评价题文本
    rateText(list) {
      if (list.length) {
        return list.map(item => item.optionTitle);
      }
      return [];
    },
    // 无封面时点击倒计时 展示收起
    showCountClick() {
      this.showCount = !this.showCount;
    },
    // 点击提交问卷，跳转提交成功页面
    clickSubmit() {
      this.clickFlag = true;
      this.questionSubmit();
      // this.$router.push({
      //   path: "/submit",
      // });
    },
    cancelFinish() {
      // 返回调研列表
      this.finishDialogVisible = false;
      // window.open("/list");
    },
    okFinish() {
      // 刷新页面
      this.finishDialogVisible = false;
      location.reload();
    },
    okSubmit() {
      // 跳转调研列表
      this.submitDialogVisible = false;
      // window.open("/list");
    },
    // 提交
    questionSubmit() {
      // console.log(this.formValue, 2222);
      this.emptyList = [];
      this.optionNumList = [];
      this.formCheck();
      if (!this.emptyList.length) {
        this.editFormValue();
        let params = {
          // libId: this.libId,
          libId: this.libId ? this.libId : "2111000036",
          channel: "YL",
          timeLong: this.questionData.countDown,
          beginTime: this.beginTime,
          endTime: this.formatDate("yyyy-MM-dd hh:mm:ss"),
          sysFlag: this.getQuery("sysFlag") ? this.getQuery("sysFlag") : "",
          answerData: [...this.answerData],
          modId: this.getQuery("modId") ? this.getQuery("modId") : ""
        };
        let temHead = localStorage.getItem(
          "__________cccccckkkkkkkkkkk_______"
        );

        getQuestionSubmit({
          ...params,
          ck: localStorage.getItem("__________cccccckkkkkkkkkkk_______")
            ? localStorage.getItem("__________cccccckkkkkkkkkkk_______")
            : ""
        }).then(res => {
          console.log(res, "请求结果");
          if (res && res.message === "success") {
            clearInterval(this.timer);
            if (this.clickFlag) {
              this.$router.push({
                path: "/submit"
              });
            }
          }
        });
      }
    },
    // 结束计时
    finishTime() {
      this.formCheck();
      if (this.emptyList.length) {
        this.finishDialogVisible = true;
        clearInterval(this.timer);
        // reload
        // 该问卷倒计时结束，请刷新页面后重新回答
      } else {
        this.submitDialogVisible = true;
        this.questionSubmit();
        // 对话框-ok => 处理formValue，请求提交接口
        // let params = {}
        // $http
        // 返回列表
      }
    },
    // 表单验空
    formCheck() {
      // 由于题目id问题无法使用element的表单校验，所以自己写判空逻辑
      const { questionList } = this.questionData;
      if (questionList.length) {
        questionList.forEach((item, index) => {
          // 必答题，根据不同种题型用questionId判断formValue里是否有值来判断该题做没做
          // 如果没做就把对应下标index放进emptyList中，显示未答提醒
          if (item.isMust === 1) {
            if (item.questionType === 1) {
              // 多选
              if (this.formValue[item.questionId].length === 0) {
                this.emptyList.push(index);
              }
            } else if (item.questionType === 3) {
              // 评价题
              if (this.formValue[item.questionId] === 0) {
                this.emptyList.push(index);
              }
            } else {
              // 其他
              if (this.formValue[item.questionId] === "") {
                this.emptyList.push(index);
              }
            }
          }

          if (item.questionType === 1) {
            let max = item.optionMax;
            let min = item.optionMin;
            if (
              !(
                this.formValue[item.questionId].length <= max &&
                this.formValue[item.questionId].length >= min
              )
            ) {
              this.optionNumList.push({
                index,
                max,
                min
              });
            }
          }
        });
      }
    },
    // 处理提交表单的数据
    editFormValue() {
      for (const key in this.formValue) {
        if (this.formValue[key] instanceof Array) {
          let str = this.formValue[key].join(",");
          this.answerData.push({
            questionId: key,
            answer: str
          });
        } else {
          this.answerData.push({
            questionId: key,
            answer: this.formValue[key]
          });
        }
      }
    },

    // 毫秒转换时分秒
    secondToTime(m) {
      if (m === 0 || m === null) {
        return "00:00:00";
      }
      let time = parseFloat(m) / 1000;
      if (null != time && "" != time) {
        if (time > 60 && time < 60 * 60) {
          var a =
            parseInt(time / 60.0) < 10
              ? "0" + parseInt(time / 60.0)
              : parseInt(time / 60.0);
          var ms = parseInt(
            (parseFloat(time / 60.0) - parseInt(time / 60.0)) * 60
          );
          var b = ms < 10 ? "0" + ms : ms;
          time = "00:" + a + ":" + b;
        } else if (time >= 60 * 60 && time < 60 * 60 * 24) {
          var H =
            parseInt(time / 3600.0) < 10
              ? "0" + parseInt(time / 3600.0)
              : parseInt(time / 3600.0);
          var ms = parseInt(
            (parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60
          );
          var M = ms < 10 ? "0" + ms : ms;
          var ss = parseInt(
            (parseFloat(
              (parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60
            ) -
              parseInt(
                (parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60
              )) *
              60
          );
          var S = ss < 10 ? "0" + ss : ss;
          time = H + ":" + M + ":" + S;
        } else {
          var d = parseInt(time) < 10 ? "0" + parseInt(time) : parseInt(time);
          time = "00:00:" + d;
        }
      }
      return time;
    }
  }
};
</script>

<style scoped lang="less">
.question {
  background-color: #fefefe;
  color: #333;
  padding-top: 4%;
  overflow: hidden;
  position: relative;

  .questionHeader {
    display: flex;
    flex-direction: column;
    text-align: left;
    .headerImg {
      padding: 0 4%;
      width: 100%;
      height: 140px;
      margin-bottom: 3%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .imgCountDown {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 62%;
      top: 4%;
      border-radius: 18px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      width: 30%;
      height: 40px;
      font-size: 14px;
      transform: translateX(0);
      transition: all 0.5s;
    }

    .questionTitle {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 5%;
      width: 100%;
      padding: 0 4%;
      display: flex;
      justify-content: space-between;
      > .titleContent {
        width: 85%;
      }
      > .clock {
        position: relative;
        font-weight: normal;
        width: 15%;
        .countDown {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          border-radius: 18px;
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
          background-color: #ffffff;
          right: 30px;
          width: 110px;
          height: 40px;
          font-size: 14px;
          transition: all 0.5s;
        }
        .countDownHide {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: -55px;
          border-radius: 18px;
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
          background-color: #ffffff;
          width: 110px;
          height: 40px;
          font-size: 14px;
          transition: all 0.5s;
        }
      }
    }
    .introduction {
      padding: 0 4%;
      font-size: 13px;
    }
  }
  .questionContent {
    margin: 0 auto 70px;
    padding: 0 4%;
    min-height: calc(~"100vh - 165px");
    .questionBody {
      padding: 3%;
      text-align: left;
      margin: 5% 0;
      background: #ffffff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
      border-radius: 10px;
      .questionParagraph {
        font-weight: 500;
        font-size: 14px;
      }
      > .el-checkbox__inner {
        align-self: center;
        position: relative;
        top: 3px;
        display: block;
      }
      .optionText {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 200px;
      }
      .questionTitle {
        margin-bottom: 15px;
        font-size: 16px;
        font-weight: 500;
        .questionTitleImg {
          display: flex;
          flex-wrap: wrap;
          margin-top: 1%;
          .imgIcon {
            width: 20px;
            height: 20px;
            position: absolute;
            top: 3%;
            left: 83%;
            background: rgba(0, 0, 0, 0.3);
            border-radius: 13px;
            display: flex;
            justify-content: center;
            align-items: center;
            i {
              font-size: 12px;
              color: #fff;
            }
          }
          img {
            width: 140px;
            height: 140px;
            border-radius: 10px;
          }
        }
      }
      /deep/ .el-radio-group {
        width: 100%;
        .el-radio {
          margin-right: 0;
        }
        .el-radio--medium.is-bordered {
          width: 100%;
          margin: auto 0;
          margin-bottom: 3%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .el-radio--medium.is-bordered.is-checked {
          background-color: #f5f8fe;
        }
        img {
          width: 200px;
          height: 200px;
          border-radius: 10px;
        }
        .imgIcon {
          width: 17px;
          height: 17px;
          position: absolute;
          top: 4%;
          left: 87%;
          background: rgba(0, 0, 0, 0.3);
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          i {
            font-size: 12px;
            color: #fff;
          }
        }
      }
      /deep/ .el-rate {
        outline: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      }
      /deep/ .el-textarea__inner {
        -webkit-tap-highlight-color:rgba(255,0,0,0);
        -webkit-appearance: none;
      }
      /deep/ .el-checkbox-group {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .el-checkbox--medium.is-bordered {
          display: flex;
          width: 100%;
          margin: auto 0;
          margin-bottom: 3%;
          .el-checkbox__input {
            line-height: 25px;
          }
          .el-checkbox__label {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 95%;
            line-height: 20px;
          }
        }
        .el-checkbox--medium.is-bordered.is-checked {
          background-color: #f5f8fe;
        }
        img {
          width: 200px;
          height: 200px;
          border-radius: 10px;
        }
        .imgIcon {
          width: 17px;
          height: 17px;
          position: absolute;
          top: 4%;
          left: 87%;
          background: rgba(0, 0, 0, 0.3);
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          i {
            font-size: 12px;
            color: #fff;
          }
        }
      }
    }
  }
  .questionSubmit {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;
    z-index: 5;
    background: linear-gradient(279.66deg, #fc3f33 7.33%, #ff5e54 85.13%);
    line-height: 50px;
    text-align: center;
    color: #fff;
    font-size: 17px;
  }
}
</style>
